import moment from "moment"
import { bignumber, multiply } from "mathjs"

export const financial = incomingValue => Number.parseFloat(incomingValue).toFixed(2)

export const centiToOne = rate => Number.parseFloat(rate / 100).toFixed(2)

export const toHumanDate = (timestamp, defText = "", short = true) => {
  if (!timestamp || timestamp === 0) {
    return defText
  }
  if (!short) {
    return moment.unix(timestamp / 1000).format("YYYY-MM-DD HH:mm:ss")
  } else {
    return moment.unix(timestamp / 1000).format("YY-MM-DD HH:mm")
  }
}

export const urllize = url => {
  if (/^https?/.test(url)) {
    return url
  } else {
    return `https:${url}`
  }
}

export const urlBase = location.protocol + "//" + location.hostname

export const watchReload = inputs =>
  inputs.reduce((acc, cur) => {
    acc[cur] = {
      deep: true,
      async handler(newV) {
        console.log(`${cur}: ${newV}`)
        await this.reload()
      },
    }
    return acc
  }, {})

export const editAndRemove = function(h, p, editEv, removeEv, removeMsg = "确定删除吗?", extraButtons = []) {
  return h("ButtonGroup", [
    h("Button", { props: { type: "success" }, on: { click: editEv } }, "编辑"),
    h(
      "Button",
      {
        props: { type: "warning" },
        on: {
          click: () => {
            this.$Modal.confirm({
              content: removeMsg,
              onOk: removeEv,
            })
          },
        },
      },
      "删除",
    ),
    ...extraButtons,
  ])
}

export const apiUtil = async function(cb, failureCb = null) {
  try {
    return await cb()
  } catch (err) {
    this.$Message.error(err.message)
    if (failureCb) {
      await failureCb()
    }
  }
}

export const convertDateFromArray = inputArray => {
  let start = null,
    end = null
  if (
    inputArray instanceof Array &&
    inputArray.length == 2 &&
    inputArray[0] instanceof Date &&
    inputArray[1] instanceof Date
  ) {
    start = inputArray[0].getTime()
    end = inputArray[1].getTime()
  }
  return { start, end }
}

export const imgHolder = (h, imgSrc, fn = () => {
}) => {
  const width = "80"
  const height = "80"
  const realImgDirective = { name: "real-img" }
  if (imgSrc) {
    realImgDirective.value = imgSrc
  }
  return h("img", {
    domProps: { width, height },
    on: { click: fn },
    directives: [realImgDirective],
  })
}

export const humanOrderStatus = status => {
  switch (status) {
    case "FINISHED":
      return "完成"
    case "PENDING":
      return "待支付"
    case "GROUP_FORMING":
      return "待成单"
    case "PAID":
      return "待发货"
    case "DELIVERED":
      return "已发货"
    case "PAID_WAITING":
      return "支付中"
    case "CLOSED":
      return "关闭"
  }
  return null
}

export const badgeOrderStatus = (h, status) => {
  switch (status) {
    case "FINISHED":
      return h("Badge", { props: { status: "success" } })
    case "PENDING":
    case "PAID_WAITING":
      return h("Badge", { props: { status: "warning" } })
    case "GROUP_FORMING":
    case "PAID":
    case "DELIVERED":
      return h("Badge", { props: { status: "processing" } })
    case "CLOSED":
      return h("Badge", { props: { status: "default" } })
  }
  return null
}

export const humanShippingFeeType = status => {
  switch (status) {
    case "FIXED":
      return "固定运费"
    case "CALC":
      return "计算运费"
    case "ONE":
      return "一口价"
    case "FREE":
      return "免运费"
  }
  return null
}

export const badgeReceiptStatus = (h, status) => {
  switch (status) {
    case "NEW":
    case "READY":
      return h("Badge", { props: { status: "processing" } })
    case "PAID":
      return h("Badge", { props: { status: "success" } })
    case "FAILED":
      return h("Badge", { props: { status: "error" } })
  }
  return null
}

export const humanReceiptStatus = status => {
  switch (status) {
    case "NEW":
      return "新建"
    case "READY":
      return "待支付"
    case "PAID":
      return "已支付"
    case "FAILED":
      return "失败"
  }
  return null
}

export const badgeWithdrawStatus = (h, status) => {
  switch (status) {
    case "FROZEN":
      return h("Badge", { props: { status: "processing" } })
    case "DONE":
      return h("Badge", { props: { status: "success" } })
    case "CANCEL":
      return h("Badge", { props: { status: "warning" } })
  }
  return null
}

export const humanWithdrawStatus = status => {
  switch (status) {
    case "FROZEN":
      return "冻结中"
    case "DONE":
      return "已完成"
    case "CANCEL":
      return "已取消"
  }
  return null
}

const reColor = /^0x([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i
const reRgba = /^rgba\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*([\d.]{1,5})\s*\)\s*$/i

const hexToDec = input => parseInt(input, 16)
const decToHex = input => parseInt(input).toString(16)

export const hexToRgba = hexColor => {
  const match = hexColor.match(reColor)
  if (!match) {
    return "rgba(255,255,255,1)"
  }
  const alpha = (hexToDec(match[1]) / 255).toFixed(3)
  const red = hexToDec(match[2])
  const green = hexToDec(match[3])
  const blue = hexToDec(match[4])
  return `rgba(${red},${green},${blue},${alpha})`
}

export const rgbaToHex = rgbaColor => {
  const match = rgbaColor.match(reRgba)
  if (!match) {
    return "0xFFFFFFFF"
  }
  const red = String(decToHex(match[1])).padStart(2, "0")
  const green = String(decToHex(match[2])).padStart(2, "0")
  const blue = String(decToHex(match[3])).padStart(2, "0")
  const alpha = String(decToHex(Math.round(match[4] * 255))).padStart(2, "0")
  return "0x" + `${alpha}${red}${green}${blue}`.toUpperCase()
}

export const enableInTree = (node, checkedList) => {
  if (node.children instanceof Array) {
    node.children.forEach(c => enableInTree(c, checkedList))
  }

  if (checkedList.includes(node.name)) {
    node.checked = true
  } else {
    node.checked = false
  }
  delete node.disabled
  delete node.disableCheckbox
}

export const disableExcept = (node, exceptList) => {
  if (node.children instanceof Array) {
    node.children.forEach(c => disableExcept(c, exceptList))
  }

  if (!exceptList.includes(node.name)) {
    node.disabled = true
    node.disableCheckbox = true
    node.checked = false
  }
}
export const copyToClipboard = function(textToCopy) {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(textToCopy)
  } else {
    // text area method
    let textArea = document.createElement("textarea")
    textArea.value = textToCopy
    // make the textarea out of viewport
    textArea.style.position = "fixed"
    textArea.style.left = "-999999px"
    textArea.style.top = "-999999px"
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    return new Promise((res, rej) => {
      // here the magic happens
      document.execCommand("copy") ? res() : rej()
      textArea.remove()
    })
  }
}

export function fixPrice(price) {
  return multiply(bignumber(price), bignumber(100.0)).toFixed()
}